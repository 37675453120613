$('#map').asyncGoogleMaps()

$('.fancybox-thumbs').fancybox({
    prevEffect: 'none',
    nextEffect: 'none',
    closeBtn: true,
    arrows: true,
    nextClick: true,
    helpers: {
        thumbs: {
            width: 50,
            height: 50
        }
    }
})

$(document).click(event => {
    let outsideClick = $(event.target)

    let navbarCollapseHasClassShow = $('.navbar-collapse').hasClass('show')

    if (navbarCollapseHasClassShow && !outsideClick.hasClass('navbar-toggler')) {
        $('.navbar-toggler').click()
    }
})

if ($(window.location.hash).length) {
    $(window.location.hash)
        .children('.card')
        .removeClass('border-0')
        .addClass('border-primary')
        .css('border-width', 'medium')
}

$(document).scroll(e => $(e.currentTarget).scrollTop() > 50 ? $('.back-to-top, .back-to-top-link').fadeIn() : $('.back-to-top, .back-to-top-link').fadeOut())