require('./bootstrap')

require('./maps')

require('./recaptcha')

require('./theme')

import ContactForm from './interactsWithContactForm'

(new ContactForm()).init();